import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CenteredCircularProgress, MIN_PNL_DISPLAY_WIDTH } from '../common/common';
import { prepareData } from '../pnlAnalytics/dataFetcher';
import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import { canAccessAccounting, MainTabs } from './common';
import OverviewTab from './management/OverviewTab';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../actions/pnlAnalytics';
import { ErrorPage, NotAuthorizedPage, UserErrorPage } from '../common/error';
import { accFetchConnector, AccMetaData, FetchAccAnalyticsPropsFromRedux } from './accDataFetcher';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerStateType } from '../reducers';
import { setFiltersAction, SetFiltersPayloadType } from '../actions/accounting';
import Filters from './Filters';
import AdminTab from './detail/AdminTab';
import VesselsTab from './detail/VesselsTab';
import { getRealUser } from '../common/auth';
import CashTab from './detail/CashTab';
import BankAccountsTab from './banks/BankAccountsTab';
import BankTransactionsTab from './banks/BankTransactionsTab';

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

type DefaultFilters = {
    hideMinorAdminCompanies: boolean;
    displayCompare: boolean;
    compareWithBudget: boolean;
    showFractions: boolean;
};

const DefaultFilter: DefaultFilters = {
    hideMinorAdminCompanies: true,
    displayCompare: true,
    compareWithBudget: false,
    showFractions: false,
};

const AccountingFilter: DefaultFilters = {
    hideMinorAdminCompanies: false,
    displayCompare: false,
    compareWithBudget: false,
    showFractions: true,
};
const userDefaultFilter = new Map<string, DefaultFilters>();
userDefaultFilter.set('eva.panousou@swisschemgas.com', AccountingFilter);
userDefaultFilter.set('ravraam@swissmarine.gr', AccountingFilter);
userDefaultFilter.set('emkaragounis@swissmarine.gr', AccountingFilter);
userDefaultFilter.set('mstavridi@swissmarine.gr', AccountingFilter);
userDefaultFilter.set('elias.constantinou@swisschemgas.com', AccountingFilter);
userDefaultFilter.set('anna.constantinou@swisschemgas.com ', AccountingFilter);
userDefaultFilter.set('filip.theodorakis@swisschemgas.com', DefaultFilter);
userDefaultFilter.set('ggiannoulopoulos@swissmarine.gr', AccountingFilter);

const defaultUserTab = new Map<string, MainTabs>();
defaultUserTab.set('dimitris.theodorakis@swisschemgas.com', MainTabs.Overview);
defaultUserTab.set('eva.panousou@swisschemgas.com', MainTabs.Cash);
defaultUserTab.set('ravraam@swissmarine.gr', MainTabs.Cash);
defaultUserTab.set('emkaragounis@swissmarine.gr', MainTabs.Cash);
defaultUserTab.set('mstavridi@swissmarine.gr', MainTabs.Cash);
defaultUserTab.set('elias.constantinou@swisschemgas.com', MainTabs.Vessels);
defaultUserTab.set('anna.constantinou@swisschemgas.com', MainTabs.Vessels);
defaultUserTab.set('filip.theodorakis@swisschemgas.com', MainTabs.Cash);
defaultUserTab.set('ggiannoulopoulos@swissmarine.gr', MainTabs.Cash);

const AccountingComp = (props: Props) => {
    // Filters
    const dispatch = useDispatch();
    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);
    console.log(props.authUser);
    let defaultTab: MainTabs = defaultUserTab.has(getRealUser(props.authUser).email)
        ? defaultUserTab.get(getRealUser(props.authUser).email)!
        : MainTabs.Overview;
    const [companiesTab, setCompaniesTab] = React.useState<MainTabs>(defaultTab);
    const handleSetCompaniesTab = (event: React.ChangeEvent<{}>, newValue: MainTabs) => {
        setCompaniesTab(newValue);
    };

    useEffect(() => {
        if (!canAccessAccounting(props.authUser)) return;
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.AccountingMeta,
            onSuccess: (data: any) => {
                // Unfortunately we need to do this check here as we don't want the filters to be reset whenever
                // this code executes
                if (filters.quickDateSelection !== null) return;
                let accMetaData = data as AccMetaData;
                let newFilters = { ...filters };
                if (userDefaultFilter.has(getRealUser(props.authUser).email)) {
                    let newDefault = userDefaultFilter.get(getRealUser(props.authUser).email)!;
                    newFilters = {
                        ...filters,
                        hideMinorAdminCompanies: newDefault.hideMinorAdminCompanies,
                        displayCompare: newDefault.displayCompare,
                        compareWithBudget: newDefault.compareWithBudget,
                        showFractions: newDefault.showFractions,
                    };
                }
                dispatch(
                    setFiltersAction({
                        ...newFilters,
                        quickDateOptions: accMetaData.quick_dates,
                        quickDateSelection: accMetaData.default_quick_date,

                        lastEntryDatetime: accMetaData.last_entry_datetime,
                        lastSyncDatetime: accMetaData.last_sync_datetime,
                    })
                );
            },
        });
    }, [props, dispatch, filters]);
    const metaData = props.pnlAnalytics.accounting_meta;

    if (!canAccessAccounting(props.authUser)) {
        return <NotAuthorizedPage msg={'Not authorized to access Accounting'} />;
    }

    if (metaData.status === PNLFetchStatus.INIT) {
        return <CenteredCircularProgress msg={'Initializing ...'} />;
    }
    if (metaData.status === PNLFetchStatus.LOADING) {
        return <CenteredCircularProgress msg={'Fetching Accounting metadata ...'} />;
    }
    if (metaData.status === PNLFetchStatus.FAILED) {
        let returnedErrorMsg: undefined | any = metaData.err!.jsonError['err_msg'];
        let errMsg: string = returnedErrorMsg ? (returnedErrorMsg as string) : metaData.err!.parsedMsg;
        return metaData.err?.status === 400 ? <UserErrorPage msg={errMsg} /> : <ErrorPage msg={errMsg} />;
    }

    if (!metaData.data) {
        return <ErrorPage msg={'While we should have had Accounting meta data for some reason we do not. This is our own bug'} />;
    }

    return (
        <div
            style={{
                minWidth: MIN_PNL_DISPLAY_WIDTH,
            }}>
            <div className={'MuiToolbar-dense'} />
            <Box className={'MuiToolbar-gutters'} my={2} style={{ backgroundColor: 'clear' }}>
                <Filters />
                <Grid item xs={12} style={{ backgroundColor: 'clear' }}>
                    <Tabs
                        style={{ backgroundColor: 'clear' }}
                        value={companiesTab}
                        onChange={handleSetCompaniesTab}
                        indicatorColor="secondary"
                        centered
                        textColor="secondary">
                        <Tab value={MainTabs.Overview} label={<MainTabLabel selection={MainTabs.Overview} />} />
                        <Tab value={MainTabs.Admin} label={<MainTabLabel selection={MainTabs.Admin} />} />
                        <Tab value={MainTabs.Vessels} label={<MainTabLabel selection={MainTabs.Vessels} />} />
                        <Tab value={MainTabs.Cash} label={<MainTabLabel selection={MainTabs.Cash} />} />
                        <Tab value={MainTabs.BankAccounts} label={<MainTabLabel selection={MainTabs.BankAccounts} />} />
                        <Tab value={MainTabs.BankTransactions} label={<MainTabLabel selection={MainTabs.BankTransactions} />} />
                    </Tabs>
                </Grid>
                <div>
                    {companiesTab === MainTabs.Overview && <OverviewTab {...props} />}
                    {companiesTab === MainTabs.Admin && <AdminTab {...props} />}
                    {companiesTab === MainTabs.Vessels && <VesselsTab {...props} />}
                    {companiesTab === MainTabs.Cash && <CashTab {...props} />}
                    {companiesTab === MainTabs.BankAccounts && <BankAccountsTab {...props} />}
                    {companiesTab === MainTabs.BankTransactions && <BankTransactionsTab {...props} />}
                </div>
            </Box>
        </div>
    );
};

const MainTabLabel = (props: { selection: MainTabs }) => {
    return (
        <span style={{ alignItems: 'center', display: 'flex', marginLeft: '20px', marginRight: '50px' }}>
            {props.selection === MainTabs.Overview && <span />}
            {props.selection === MainTabs.Admin && <span />}
            {props.selection === MainTabs.Vessels && <span />}
            {props.selection === MainTabs.Cash && <span />}
            <span style={{ paddingLeft: '10px' }}>{props.selection}</span>
        </span>
    );
};

export default accFetchConnector(AccountingComp);
